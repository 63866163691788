<i18n locale="th" lang="yaml" src="@i18n/service/service.th.yaml"></i18n>
<i18n locale="th" lang="yaml" src="@i18n/service/service-job.th.yaml"></i18n>
<i18n locale="th" lang="yaml" >
service_table.field.jobs_detail : "งานที่ต้องทำ :"
service_table.field.jobs_detail.unspecify : "ไม่ระบุงาน"
service_table.field.open_type : "รับงานจาก :"
service_table.field.return_type : "ส่งคืน :"
service_table.field.return_type.refer : "ส่งต่อ :"
</i18n>

<template>
	<div class="service-detail-table">
		<div class="row service-field-jobs_detail">
			<label>{{$t('service_table.field.jobs_detail')}}</label>
			<span class="value">
				{{jobsDetail}}
			</span>
		</div>
		<div class="row">
			<label>{{$t('service_table.field.open_type')}}</label>
			<span class="value">
				{{openTypeDisplay}}
			</span>
		</div>
		<div v-if="showReturn" class="row">
			<label>{{returnTypeLabel}}</label>
			<span class="value">
				{{returnTypeDisplay}}
			</span>
		</div>
	</div>
</template>

<script>
import { getServiceJobsList, getOpenTypeDisplay, getReturnTypeDisplay, SERVICE_STATUS, RETURN_TYPE } from "@utils/serviceUtil"

export default {
	props : {
		serviceData : {
			type : null,
			default : () => []
		}
	} ,
	computed : {
		jobsDetail() {
			const list = getServiceJobsList(this,this.service)
			let detailDisplay = (list.length === 0) ? this.$t('service_table.field.jobs_detail.unspecify') : list.join(', ')
			if (this.serviceData.service.serviceIsDeviceOnly) {
				detailDisplay += " ["+this.$t('service.field.is_device_only')+"]"
			}
			return detailDisplay
		} ,
		openTypeDisplay() {
			return getOpenTypeDisplay(this, this.serviceData)
		} ,
		showReturn() {
			return this.serviceData?.service.serviceStatus === SERVICE_STATUS.STATUS_CLOSE
		} ,
		returnTypeLabel() {
			if (!this.showReturn)
				return null
			const returnType = this.serviceData.service.serviceReturnReturnType
			if (returnType === RETURN_TYPE.REFER)
				return this.$t('service_table.field.return_type.refer')
			else
				return this.$t('service_table.field.return_type')
		} ,
		returnTypeDisplay() {
			if (!this.showReturn)
				return null
			return getReturnTypeDisplay(this, this.serviceData)
		} ,
	}
}
</script>

<style lang="less" scoped>
.service-detail-table > .row {
	label {
		color : @primary-color;
	}
}

</style>
