<i18n locale="th" lang="yaml" >
status.reason : "เหตุผล"
</i18n>

<template>
	<MyTooltip display-mode="white">
		<slot slot="title" name="content">
			<div class='tooltip-status'>{{statusDescription}}</div>
			<div v-if="$notEmpty(reason)" class="tooltip-reason">
				<label>{{$t('status.reason')}}</label> :
				<div v-trim class="multiline">
				{{reason}}
				</div>
			</div>
		</slot>

		{{status}}
		<StatusIcon v-if="showIcon" :class="iconCss" title=""/>
	</MyTooltip>
</template>

<script>
import MyTooltip from "@components/common/MyTooltip.vue"
import StatusIcon from "vue-material-design-icons/Circle.vue"
export default {
	components : {
		StatusIcon,MyTooltip,
	} ,
	props : {
		status : {
			type : String ,
			default : undefined
		} ,
		reason : {
			type : String,
			default : undefined,
		} ,
		iconMode : {
			type : String,
			default : undefined,
		} ,
		description : {
			type : String,
			default : undefined,
		}
	} ,
	computed : {
		showIcon() {
			return this.$notEmpty(this.iconMode)
		} ,
		statusDescription() {
			return this.$notEmpty(this.description) ? this.description : this.status
		} ,
		iconCss() {
			return [
				'anticon' , 'status-icon' ,
				{
					'text-success' : this.iconMode == 'success' ,
					'text-error' : this.iconMode == 'error' ,
					'text-warning' : this.iconMode == 'warning' ,
				}
			]
		}
	}
}
</script>

<style lang="less" scoped>
.status-icon {
	font-size : 0.6em;
	height : 0.8em;
	vertical-align: middle;
}
.tooltip-reason {
	margin-top : 8px;
	margin-bottom : 4px;
	font-size : 0.9em;
	padding-right : 12px;
	> label {
		text-decoration: underline double;
	}
	> div {
		margin-top : 2px;
		margin-left : 12px;
	}
}
</style>
