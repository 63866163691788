<i18n src="@i18n/service/service.th.yaml" locale="th" lang="yaml"/>
<i18n locale="th" lang="yaml">
service_table.field.service_no : "ใบงาน"
service_table.field.open_detail : "รับเรื่อง"
service_table.field.status.service : "สถานะใบงาน"
service_table.field.status.repair : "งานซ่อม"
service_table.field.status.payment : "ชำระเงิน"
</i18n>

<template>
	<a-table
		v-bind="$attrs"
		class="mytable mytable-scroll service-table"
		size="small"
		:pagination="pagination"
		v-on="$listeners">
		<a-table-column
			:title="$t('common.table.column.no')"
			align="center"
			class="column-row-no">
			<template v-slot="text,record,index">
				{{index + 1 + ((pagination.current - 1) * pagination.pageSize)}}
			</template>
		</a-table-column>
		<a-table-column
			key="serviceNo" :sorter="true"
			:title="$t('service_table.field.service_no')"
			data-index="service.serviceNo"
			class="column-service_no"
			:width="135">
			<template v-slot="text">
				{{text}}
			</template>
		</a-table-column>
		<a-table-column
			v-if="!hideColumnDrone"
			key="drone.name"
			:title="$t('service.field.drone')"
			:width="125"
			align="center"
			data-index="droneData">
			<template v-slot="droneData">
				<my-router-link v-if="droneData.drone" name="drone/view" :param-value="droneData.drone.serialNo" :new-window="true" param-name="serial_no">
					{{droneData.drone.name}}
					<span class="drone-serial-no">({{droneData.drone.serialNo}})</span>
				</my-router-link>
			</template>
		</a-table-column>
		<a-table-column
			key="detail"
			class="service-detail-col"
			:title="$t('common.table.column.detail')">
			<template v-slot="record">
				<ServiceDetailTableField
					:service-data="record"/>
			</template>
		</a-table-column>
		<a-table-column
			v-if="!hideColumnCompany"
			key="userCompanyId"
			:title="$t('service.field.company')"
			data-index="reportBy"
			:width="100"
			align="center">
			<template v-slot="userData" >
				<ImageName :name="userData.company.name" size="very-tiny" :src="userData.company.image_url">
					<a-icon type="shop" />
				</ImageName>
				<div>
				</div>
			</template>
		</a-table-column>
		<a-table-column
			key="userUsername" :sorter="true"
			:title="$t('service_table.field.open_detail')"
			:width="115"
			class="column-open"
			align="center">
			<template v-slot="{ service, reportBy }" >
				<div>
					{{$dayjs(service.serviceOpenDate).format("D MMM YY")}}
				</div>
				<div>
					{{$t('common.by')}}
					<my-router-link name="user/view" :param-value="reportBy.user.id" new-window>
						{{reportBy.user.username}}
					</my-router-link>
				</div>
			</template>
		</a-table-column>
		<a-table-column
			key="serviceUpdatedDatetime" :sorter="true"
			:width="135"
			:title="$t('service.field.updated')"
			class="column-updated"
			align="center">
			<template v-slot="{ service, updatedBy: userData }" >
				<div>
				{{$dayjs(service.serviceUpdatedDatetime).format("D MMM YY")}}
				<a-icon type="clock-circle" /> {{$dayjs(service.serviceUpdatedDatetime).format("H:mm")}}
				</div>
				<div>
					{{$t('common.by')}}
					<my-router-link name="user/view" :param-value="userData.id" new-window>
						{{userData.user.username}}
					</my-router-link>
				</div>
			</template>
		</a-table-column>
		<a-table-column
			key="serviceStatus"
			:title="$t('service_table.field.status.service')"
			:width="120"
			class="column-status"
			align="center">
			<template v-slot="{ service }">
				<StatusDot
					class="service-field-status"
					:status="$tenum('service.status', service.serviceStatus)"
					:description="$t('enum.service.status.' + service.serviceStatus + '.description')"
					:reason="service.serviceStatusReason"
					:icon-mode="getDotMode('service', service.serviceStatus)" />
			</template>
		</a-table-column>
		<a-table-column
			key="serviceRepairStatus"
			:title="$t('service_table.field.status.repair')"
			:width="120"
			class="column-status"
			align="center">
			<template v-slot="{ service }">
				<StatusDot
					v-if="repairStatus(service)"
					class="value"
					:status="$tenum('service.repair.result', repairStatus(service).result)"
					:reason="repairStatus.reason"
					:icon-mode="getDotMode('repair', repairStatus(service).result)" />
			</template>
		</a-table-column>
		<a-table-column
			key="servicePaymentStatus"
			:title="$t('service_table.field.status.payment')"
			:width="120"
			class="column-status"
			align="center">
			<template v-slot="{ service }">
				<StatusDot
					v-if="paymentStatus(service)"
					class="value"
					:status="$tenum('service.payment.status', paymentStatus(service))"
					:icon-mode="getDotMode('payment', paymentStatus(service))" />
			</template>
		</a-table-column>
		<a-table-column
			class="mytable-action-col"
			:width="50"
			align="center">
			<template v-slot="{ service }">
				<my-router-link v-if="isShowView(service)" name="service/view" :param-value="service.id">
					<a-icon type="search" />
				</my-router-link>
			</template>
		</a-table-column>
	</a-table>
</template>

<script>
import {Table} from "ant-design-vue"
import ImageName from "@components/common/ImageName.vue"
import ServiceDetailTableField from "@/src/components/service/ServiceDetailTableField.vue"
import StatusDot from "@components/common/StatusDot.vue"
import { lookupStatusDotMode,isStatusAllowView, SERVICE_STATUS_FOR_PAYMENT, PAYMENT_STATUS} from '@utils/serviceUtil'

export default {
	components : {
		"a-table" : Table, "a-table-column" : Table.Column ,
		ImageName,ServiceDetailTableField,StatusDot,
	} ,

	props : {
		pagination : {
			type : null,
			default : () => []
		} ,
		hideColumns : {
			type : null,
			default : () => []
		}
	} ,
	computed : {
		hideColumnDrone() {
			return this.$notEmpty(this.hideColumns) && this.hideColumns.includes('drone')
		} ,
		hideColumnCompany() {
			return this.$notEmpty(this.hideColumns) && this.hideColumns.includes('company')
		} ,
	} ,
	methods : {
		repairStatus(service) {
			return {
				result: service.serviceRepairRepairResult,
				reason: service.serviceRepairRepairResultReason
			}
		} ,
		paymentStatus(service) {
			if (!service.servicePaymentStatus && Object.values(SERVICE_STATUS_FOR_PAYMENT).includes(service.serviceStatus)) {
				return PAYMENT_STATUS.STATUS_NEW
			}
			return service.servicePaymentStatus
		} ,
		getDotMode(type,status) {
			return lookupStatusDotMode(type,status)
		} ,
		isShowView(service) {
			if (this.$authorize('list','service',{companyId : service.company_id}))
				return true
			else
				return isStatusAllowView('details',service.status)
		}
	}

}
</script>

<style lang="less" scoped>
.service-table::v-deep table {
	min-width : 1200px;
	td.service-detail-col {
		min-width : 200px;
		font-size : 0.9em;
		vertical-align: top;
	}
	td.column-service_no,
	td.column-updated ,
	td.column-open,
	td.column-status {
		font-size : 0.95em;
	}
}
.drone-serial-no {
	display : block;
	font-size : 0.9em;
	margin-top : 2px;
}
</style>
